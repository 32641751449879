import React from "react";
import "./HomePage.css"
import { Box, Button, Grid2, IconButton, Stack, TextField, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
// import searchIcon from "../assets/search-svgrepo-com.svg"
// import SvgSearchIcon from "./SvgSearchIcon";
import Map from "./Map";


export default function HomePage() {
    // const [data, setData] = React.useState([[0]]);
    const [forcast, setForcast]: any = React.useState()

    React.useEffect(() => {
      fetch("/api")
        .then((res) => res.json())
        // .then((data) => parseNOAAForcast(data.forcast))
        .then((data) => setForcast(forcastToMLat(parseNOAAForcast(data.forcast))))
        // .then((data) => console.log(data))
        // .then((_) => setForcast(forcastToMLat(data)))
        // .then((_) => console.log("hi there: " + forcast))
    }, []);

    return (
        <Stack 
          className="HomePage"
          sx={{ display: 'flex'}}
          padding="1rem" 
        >        
          <Box 
            sx={{
              minWidth: "20rem",
              maxWidth: "70rem",
              margin: "auto",
              border: 1,
              bgcolor: "#1b3a4b",
              alignContent: "center",
              boxShadow:1,
              borderRadius: 8
            }}
          >
            <Typography
              variant="h4"
              padding={"1.25rem"}
              color="white"
            >
              Tired of missing the Northern Lights?
            </Typography>
          </Box> 
          
          <Grid2 
            container
            spacing={2}
            sx={{
              className:"MapBox",
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              padding:"1rem",
            }}
          >
          
          {/* <Map forcast={forcastToMLat(data)}/> */}
          {!forcast ? "Loading Map..." : <Map forcast={forcast}/>}
          

          <Stack
            sx={{
              backgroundColor: "#778da9",
              flexWrap: 'wrap',
              boxShadow: 10
              // color: "#ffffff"
            }}
            borderRadius={3}
            borderColor={"#000000"}
            maxHeight={"30rem"}
            maxWidth={"30rem"}
            minHeight={"20rem"}
            minWidth={"20rem"}
            direction={"row"}
          >
            <Grid2 container wrap="nowrap" height={"2rem"} width={"40rem"}>
              <TextField 
                fullWidth
                label="Address Lookup"
                variant="filled"
                sx={{
                  // Root class for the input field
                  "& .MuiFilledInput-root": {
                    color: "#ffffff",
                    // fontFamily: "Arial",
                    fontWeight: "bold",
                    // backgroundColor: "#f4f4f4",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                    "&:before": {
                      borderColor: "#2e2e2e",
                      borderWidth: "2px",
                    },
                    "&:after": {
                      borderColor: "#ffffff",
                      borderWidth: "2px",
                      color: "#ffffff"
                    },
                  },
                  // Class for the label of the filled input field
                  "& .MuiInputLabel-filled": {
                    color: "#ffffff",
                    fontWeight: "bold",
                    // backgroundColor: "red"
                  }}}
                />
              <IconButton 
                style={{borderRadius: 4}} 
                sx={{ width: "3.5rem", height: "3.5rem", backgroundColor: "#1b3a4b", color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#aaaaaa"
                  },
                }}>
                <SearchIcon/>
              </IconButton>
              {/* <Button variant="contained" startIcon={SvgSearchIcon}/> */}
              {/* <Button variant="contained" startIcon={"."}/> */}

              {/* <SvgSearchIcon/> */}
            </Grid2>
          </Stack>
          </Grid2>
        </Stack>
    );
  }




function parseNOAAForcast(forcast: string) {
  let parsedData: any[] = forcast
                        .split("00-03UT")[1]
                        .split("Rationale:")[0]
                        .replace(/\(G\d\)+/g, "") // get rid of GeoMagnetic storms
                        .replace(/\d\d-\d\dUT+/g, "") // get rid of time zones
                        .replace(/\s+/g, " ") // remove reduntant spaces
                        .split(" ");

  parsedData.pop()
  parsedData.shift() //  gets rid of first and last space
  
  parsedData = parsedData.map((x) => parseFloat(x))
  
  let parsedForcast: any[] = []
  let todayArray: any[] = []
  let tomorrowArray: any[] = []
  let twoDaysArray: any[] = []

  while (parsedData.length > 1) {
    todayArray.push(parsedData.shift())
    tomorrowArray.push(parsedData.shift())
    twoDaysArray.push(parsedData.shift())
  }
  parsedForcast = [todayArray, tomorrowArray, twoDaysArray]

  // console.log("parseNoaa: "+ parsedForcast)

  return parsedForcast
}




function forcastToMLat(forcast: number[][] ) {
  let visibleLatForcast: number[][] = []

  forcast.forEach((day) => {
    visibleLatForcast.push(day.map((x) => (-0.02159*x**2 - (1.789 * x) + 65.625)))
  } )

  // console.log("forcastToMLat: " + visibleLatForcast)
  return visibleLatForcast
}

// reading guide:
// along the top is today, tomorrow, two days from now

// along the left is:
// '00-03UT 
// '03-06UT 
// '06-09UT 
// '09-12UT 
// '12-15UT 
// '15-18UT 
// '18-21UT 
// '21-00UT 