// Map.tsx
import React, { useState } from "react";
import "./index.css"
import { MapContainer, Marker, Polyline, Popup, TileLayer, useMapEvents } from "react-leaflet";
import { LatLngTuple } from "leaflet";
import CustomMarker from "./CustomMarker";
import AuroraViewLine from "./AuroraViewLine";

// const AuroraSizeTemp = 48


export default function Map(forcast : any) {
  // const GeoMagneticNorthPole: LatLngTuple = [80.8, -85.6];
  const GeoMagneticNorthPole: LatLngTuple = [80.1, -72.2];
  const [geoMagneticLatitude, setGeoMagneticLatitude] = useState(0);
  const [position, setPosition] = useState(null);
  const [auroraSize, setAuroraSize] = useState(90);
  // const [auroraViewLine]

  React.useEffect(() => {
    if (forcast.forcast){
      setAuroraSize(Math.min(...forcast.forcast[0]))
    }
  }, []);

  // console.log(forcast)

  function LocationMarker() {
    const map = useMapEvents({
      click(e: any) {
        setPosition(e.latlng);
        // map.flyTo(e.latlng, map.getZoom());
        const distanceInKM = (map.distance(GeoMagneticNorthPole, e.latlng)/1000)
        setGeoMagneticLatitude(90 - ((distanceInKM)/111))
      },
    });

    return position === null ? null : (
      <>
      <CustomMarker position={position} >
        <Popup>
          [{position['lat']}, {position['lng']}], mLat: {geoMagneticLatitude}
        </Popup>
      </CustomMarker>
      <Polyline positions={[[position['lat'], position['lng']], GeoMagneticNorthPole]}>

      </Polyline>
      </>
    );
  }
  
  
  return (
    <>
    <MapContainer center={[20, 0]} zoom={2} scrollWheelZoom={true} >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        noWrap={true}
      />
      <Marker position={GeoMagneticNorthPole}>
        <Popup>
          Magnetic North Pole as of 2024! [{GeoMagneticNorthPole[0]}, {GeoMagneticNorthPole[1]}]
        </Popup>
      </Marker>
      <AuroraViewLine GeoMagneticNorthPole={GeoMagneticNorthPole} projectedLatitude={auroraSize}/>
      {/* <AuroraViewLine GeoMagneticNorthPole={GeoMagneticNorthPole} projectedLatitude={AuroraSizeTemp}/> */}
      <LocationMarker />
    </MapContainer>
    </>
  );
};


