import { Polygon } from "react-leaflet";
import { latLng, LatLng } from "leaflet";

function AuroraViewLine( GeoMagneticWrapper: any) {

    // console.log(GeoMagneticWrapper)
    const topRight: LatLng = latLng(90, 180)
    const topLeft: LatLng = latLng(90, -180)
    let tracedLine = [topRight, topLeft]
    let longitude = -180
    while (longitude <= 180) {
      let point: LatLng = overUnder(latLng(90, longitude), latLng(0, longitude), GeoMagneticWrapper.GeoMagneticNorthPole, GeoMagneticWrapper.projectedLatitude)
      tracedLine.push(point)      
      longitude += 1
    }

    return (
      <Polygon 
        positions={tracedLine}
        pathOptions={{ color: "green" }}
        >
      </Polygon>
    )
}


function overUnder(top: LatLng, bottom: LatLng, geoMagneticNorthPole: LatLng, goalLatitude: number): LatLng {
  let mid : LatLng = latLng( ((top.lat + bottom.lat)/2 ), top.lng)
  if (goalLatitude === 90) {
    return latLng(90, top.lng)
  }
  // console.log("top: "+ top)
  // console.log("bottom: "+ bottom)
  // console.log("mid: "+mid)
  // console.log("+++++++++++++"+geoMagneticNorthPole)
  // console.log("$$$$$$$$$$"+goalLatitude)
  let distanceInKM = (mid.distanceTo(geoMagneticNorthPole)/1000)
  let geoMagneticLatitude = (90 - ((distanceInKM)/111))
  
  if ( Math.abs(goalLatitude - geoMagneticLatitude) < .005) {
    return mid
  }
  if (geoMagneticLatitude < goalLatitude) {
    return overUnder(top, mid, geoMagneticNorthPole, goalLatitude)
  }
  else {
    return overUnder(mid, bottom, geoMagneticNorthPole, goalLatitude)
  }
}

export default AuroraViewLine;


